import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import Container from '../components/Layout/SharedStyles/Container';
import ContactForm from '../components/Forms/ContactForm';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import PageLead from '../components/PageLead/PageLead';
import FeaturedCardsComponent from '../components/Cards/FeaturedCardsComponent';

const ContactPages = ({
  data: {
    datoCmsContactPage: {
      title,
      id,
      metaTags,
      cta,
      image,
      introText,
      pathways,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} cta={cta} />
      <Container width="medium" pt={0} pb={2}>
        <PageLead leadText={introText} />
        <ContactForm locale={pageContext.locale} image={image} />
      </Container>
      <FeaturedCardsComponent pathways={pathways}></FeaturedCardsComponent>
    </PageWrapper>
  );
};

export default ContactPages;

export const query = graphql`
  query ContactPageQuery($locale: String!) {
    datoCmsContactPage(locale: { eq: $locale }) {
      locale
      title
      introText
      cta {
        id
        page {
          ... on DatoCmsHomepage {
            id: originalId
          }
          ... on DatoCmsOtherPage {
            id: originalId
          }
          ... on DatoCmsCollection {
            id: originalId
          }
          ... on DatoCmsDealerPage {
            id: originalId
          }
          ... on DatoCmsContactPage {
            id: originalId
          }
        }
        text
      }
      metaTags {
        title
        description
        image {
          url
        }
      }
      id: originalId
      image {
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { w: "1400", h: "700", fit: "crop", q: 70 }
          )
        }
      }
      pathways {
        heading
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fm: "webp", h: "270", fit: "crop", w: "440", q: 60 }
          )
        }
        link {
          ...Link
        }
        subHeading
        text
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
